import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"

import SingleColumnBlock from "@components/singleColumnBlock"

import {
  RequestMoreInfo,
} from "@components/Forms"

const NotFoundPage = () => (
  <PageLayout>
    <SEO title="404 | Requested Page Not Found | Big Ragoo" />
    <SingleColumnBlock
      textPadded
      text={
        <div>
          <h1>That page has sunk beneath the waves.</h1>
          <p>I see you are shipwrecked. The sea takes us all, eventually. See if you can swim to saftey on the <a href="/">homepage</a> where at least there is great music to entertain you while you wait for the sharks.</p>
          <h4>If you want to put a message in a bottle, do so here:</h4>
          <RequestMoreInfo />
        </div>
      }
    />
  </PageLayout>
)

export default NotFoundPage
